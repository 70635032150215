import { AnimatePresence } from "framer-motion";

import { KitchenMenuDialog } from "./KitchenMenuDialog";
import { KitchenProductSelectorDialog } from "./KitchenProductSelectorDialog";
import { FadeIn, SlideUp } from "../Animations";
import { ProductNotFoundDialog } from "../ProductNotFound";
import { SpinnerOverlay } from "../Spinner";
import { useRootState } from "../state/StateProvider";
import { useDelayedCondition } from "../useDelayedCondition";

export const Kitchen = () => {
    const { state, dispatch } = useRootState("scan");

    const isLoading =
        state.subState.kind === "kitchen" &&
        state.subState.subState.kind === "loading";
    const displayLoadingIndicator = useDelayedCondition(isLoading, 2000);

    return (
        <AnimatePresence>
            {state.subState.kind === "kitchen" && (
                <div data-theme="kitchen">
                    <FadeIn className="pt-30 fixed inset-0 bg-primary-900 bg-opacity-75" />
                    <SlideUp
                        key="select"
                        className={`fixed inset-x-0 bottom-0 ${
                            state.accessibilityMode ? "top-80" : "top-0"
                        } z-10 rounded-t-xl`}
                    >
                        {displayLoadingIndicator && <SpinnerOverlay />}
                        {state.subState.subState.kind === "error" && (
                            <ProductNotFoundDialog
                                onOk={() =>
                                    dispatch({ kind: "hideProductNotFound" })
                                }
                            />
                        )}
                        <KitchenMenuDialog
                            cartQuantity={state.cart.lineItems.length}
                            cartGrossPrice={state.cart.grossPrice}
                            cartTotalPrice={state.cart.totalPrice}
                            kitchenMenu={state.subState.menu}
                            onCancel={() =>
                                dispatch({ kind: "hideKitchenMenu" })
                            }
                        />

                        {state.subState.subState.kind === "productSelector" && (
                            <KitchenProductSelectorDialog
                                product={state.subState.subState.product}
                                onConfirm={(
                                    productId,
                                    barcode,
                                    quantity,
                                    addOnOptionIds,
                                    comboBarcodes,
                                    upsellingProductBarcodes
                                ) => {
                                    dispatch({
                                        kind: "addKitchenProduct",
                                        productId,
                                        barcode,
                                        quantity,
                                        addOnOptionIds,
                                        comboBarcodes,
                                        upsellingProductBarcodes
                                    });
                                }}
                                onCancel={() =>
                                    dispatch({
                                        kind: "hideKitchenProductSelector"
                                    })
                                }
                            />
                        )}
                    </SlideUp>
                </div>
            )}
        </AnimatePresence>
    );
};
