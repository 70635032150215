import { useRef } from "react";

import { ChangeLanguageDialog } from "./ChangeLanguageDialog";
import { OperatorModeDialog } from "./OperatorModeDialog";
import { PaymentResponseSimulator } from "./PaymentResponseSimulator";
import { StartOverDialog } from "./StartOverDialog";
import avecLogo from "../../../assets/logo-avec.svg";
import kkioskLogo from "../../../assets/logo-kkiosk.svg";
import oxxoLogo from "../../../assets/logo-oxxo.svg";
import { useConfig } from "../ConfigProvider";
import { useKonami } from "../hooks/useKonami";
import { BackToScreensaver } from "../operator/OrderList";
import { useAnyState } from "../state/StateProvider";

export const Header = () => {
    const { tenant } = useConfig();
    const { state, dispatch } = useAnyState();

    const ref = useRef<HTMLImageElement>(null);

    const headerActions = () => {
        switch (state.kind) {
            case "welcome":
                return (
                    <>
                        <OperatorModeDialog />
                        <ChangeLanguageDialog />
                    </>
                );
            case "scan":
                return (
                    <>
                        {tenant === "oxxo" && <PaymentResponseSimulator />}
                        <StartOverDialog />
                        <ChangeLanguageDialog />
                    </>
                );
            case "checkoutSuccess":
                return (
                    <>
                        <ChangeLanguageDialog />
                    </>
                );
            case "flappy":
                return <StartOverDialog />;
            case "operator":
                return (
                    <>
                        <BackToScreensaver />
                        <ChangeLanguageDialog />
                    </>
                );
            case "error":
                return <></>;
            default:
                return state satisfies never;
        }
    };

    useKonami({
        element: ref.current as Element,
        code: [1, 2, 2, 1],
        onSuccess: () => {
            dispatch({ kind: "flap" });
        }
    });

    const envBadge = import.meta.env.DEV ? (
        <div
            className={`fixed
            left-[-80px] top-[45px]
            z-50 w-[250px]
            origin-top -rotate-45 bg-cyan-500
            py-1 text-center
            text-sm
            font-bold
            text-white`}
        >
            {import.meta.env.MODE}
        </div>
    ) : undefined;

    const logo = (function () {
        switch (tenant) {
            case "avec":
                return avecLogo;
            case "kkiosk":
                return kkioskLogo;
            case "oxxo":
                return oxxoLogo;
        }
    })();

    return (
        <>
            {envBadge}
            <div className="flex w-full items-center justify-between p-4">
                <img ref={ref} src={logo} />
                <div className="flex gap-4">{headerActions()}</div>
            </div>
        </>
    );
};
