import { ScannedCode } from "../../types/Action";
import { useConfig } from "../ConfigProvider";
import { requireOneOfStates } from "../state/Common";
import { useAnyState } from "../state/StateProvider";

export const useOnScanProduct = () => {
    const { state, dispatch } = useAnyState();

    requireOneOfStates(state, "scan");

    const { subState } = state;
    const config = useConfig();

    return (scannedCode: ScannedCode) => {
        if (subState.kind === "checkingOut") {
            console.warn(
                `Scanned code ${scannedCode.code} ignored while in sub-state ${subState.kind}`
            );
            return;
        } else if (
            subState.kind === "ageVerification" &&
            subState.status === "unconfirmed"
        ) {
            dispatch({
                kind: "scanId",
                identityDocumentScan: scannedCode,
                attemptedAction: subState.attemptedAction
            });
            return;
        }

        const couponCode = config.couponCodes.find(
            (c) => c === scannedCode.code
        );

        if (couponCode) {
            dispatch({
                kind: "applyCoupon",
                code: couponCode
            });
            return;
        }

        dispatch({
            kind: "addProduct",
            payload: scannedCode
        });
    };
};
